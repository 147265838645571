import { Component  } from "react";
import WordTree from  '../Components/WordTree/WordTree'
import WordTree2 from "../Components/WordTree/WordTree2";
import './IntroView.css';


class HomeView extends Component{
    constructor(props){
        super(props)
    }

    render(){
        return(
            <div className="container intro-view">
                {/**<WordTree2 />*/}
                <WordTree />
            </div>
        )
    }
}

export default HomeView;