import React, {Component} from 'react';
import axios from 'axios';
import "./Introduction.css";
//import "./report.css";
import "./plus.css";
import SubjectButton from "../SubjectButton/SubjectButton";
import Bible from '../Bible/Bible';
import ModalReportError from '../ModalReportError/ModalReportError';
import SocialShare from '../SocialShare/SocialShare';
import {globals} from '../Globals/Globals';
import { Button } from 'react-bootstrap';

class Introduction extends Component {
    constructor(props){
        super(props);
        this.state = {list_no_fait:null, subjectData : null, toggleMenu:false};
    }

    fetchSubjectData = async (next_subject) =>{
        if (next_subject == null) return
        const request = `https://subject-data-service.neuronal.bible/subjectdata?list_no_fait=${next_subject.list_no_fait}`;
        const fetchSubjectData = await axios.get(request);//Call subject-data-service
        this.setState({subjectData: fetchSubjectData.data}, ()=>{console.log("subjectData state set from Introduction component =>", this.state.subjectData);});
    }

    componentDidMount = () => {
        console.clear()
        console.log("Intrtoduction : this.props.subject => ", this.props.subject)
        this.fetchSubjectData(this.props.subject);
    }

    componentDidUpdate(prevProps) {
        // Typical usage (don't forget to compare props):
        console.log("Introduction::componentDidUpdate")
        if (this.props.subject !== prevProps.subject) {
          this.fetchSubjectData(this.props.subject);
        }
    }

    toggleMenu = (e) => {
        e.preventDefault();
        this.setState({menu_open : !this.state.menu_open})
    }

    renderSubjectData = () => {
        return (
            <div data-tag="introduction" class="show-image">
                {/* Social networking */}
                <SocialShare subject={this.state.subjectData}/>
                {/* Modal reporting 
                <ModalReportError subject={this.state.subjectData}/>*/}
                {/* Report  / Advice */}
                <hr/>
                
                {/* Introduction */}
                {this.state.subjectData.introduction &&
                    <p>
                        <div className='subject-introduction' contentEditable={false} dangerouslySetInnerHTML={{__html : this.state.subjectData.introduction}} /> 
                    </p>
                }
                {/* filliation */}
                {this.state.subjectData.pere &&
                    <span>
                        {(this.state.subjectData.sexe === 'H') &&
                            <span className ="light-italic">&nbsp;*fils de&nbsp;</span>
                        }
                        {(this.state.subjectData.sexe === 'F') &&
                            <span className ="light-italic">&nbsp;*fille de&nbsp;</span>
                        }
                        <SubjectButton list_no_fait={this.state.subjectData.pere} text={this.state.subjectData._pere} handler={this.props.handler}></SubjectButton>
                    </span>
                }

                {/** Genealogy on demand */}
                {globals.parameters.local && <div><Button onClick={()=>{alert(`Chargement de la généalogie de "${this.state.subjectData.nom}"`)}}>Généalogie...</Button></div>}
                        
                {this.state.subjectData.tribue_ville &&
                <span>
                    ,&nbsp;originaire de &nbsp;<span class="personnage">{this.state.subjectData.tribue_ville}</span>
                </span>
                }
                <span>.</span>
        
                {this.state.subjectData.bible &&
                <p>
                    <span class="light-italic">Première apparition dans la Bible&nbsp;:&nbsp;</span>
                    <Bible bibleref={this.state.subjectData.bible}/>
                </p>
                }
            </div>
       );
    }
      
    render(){
        if (this.state.subjectData == null)
            return(<p></p>);
        else
            return this.renderSubjectData();
    }
};   

export default  Introduction;