import React from "react";
import { useParams } from "react-router-dom"
import { Button } from "react-bootstrap";
import { Chart } from "react-google-charts";
import axios from "axios";
import { globals } from "../Globals/Globals";
import { useEffect, useState } from "react";
import { useHistory } from 'react-router';
import "./WordTree.css";

const WordTree = props => {
    let history = useHistory();
    const Jesus_King_of_king = "04241b02-4c8d-4eed-ba5c-3985b1b71745";
    let [subjectTreeData, setSubjectTreeData] = useState(null)
    let [prevNoFait, setPrevNoFait] = useState(null)
    let [interactiveTable, setInteractiveTable] = useState(null)
    let [subjectSource, setSubjectSource] = useState(null);
    let [firstLaunch, setFirstLaunch] = useState(true)
    let { no_list_fait } = useParams(Jesus_King_of_king);
    
    useEffect(()=>{
        //Detect no_list_fait didchange
        if (!globals.parameters.debugging){
            //console.clear()
            console.log("no_list_fait:", no_list_fait)
            console.log("subjectTreeData:", subjectTreeData)       
        }

        if ((no_list_fait != prevNoFait) && (no_list_fait != null)){
            //Parameter has really changed
            if (globals.parameters.debugging){
              console.log("Parameter has changed !")
              console.log("prevNoFait =>", prevNoFait)
              console.log("no_list_fait =>", no_list_fait)
            }
            
            setPrevNoFait(no_list_fait)
            fetchSubjectWordTree(no_list_fait)
        }     
        
        if ((no_list_fait == null) && firstLaunch){
            setFirstLaunch(false);//avoid recurcive
            console.log("Overriding with Jesus !!")
            //Override no_list_fait parameter with Jésus-Christ - King of king
            setPrevNoFait(Jesus_King_of_king);
            fetchSubjectWordTree(Jesus_King_of_king)
        }
    })

  const fetchSubjectWordTree = async (p_s_list_no_fait) => {
    const fetchresult = await axios.get(`${globals.list_service_subject_word_tree_endpoint.url}?list_no_fait=${p_s_list_no_fait}`);
    if (globals.parameters.debugging)
      console.log(fetchresult.data.items);
    let wtData = []
    let interactive_table = {}

    wtData.push(["Phrases", ""])

    if (!((fetchresult.data.items) && (fetchresult.data.items.length > 0) && (fetchresult.data.state))) {
      console.error("An error occurs")
      return;
    }

    //Retrieve source subject
    console.log("fetchresult",fetchresult)
    console.log("fetchresult.data.items[0]",fetchresult.data.items[0])
    setSubjectSource(fetchresult.data.items[0].phrase.split(" ")[0])

    //fetchresult.data.items.forEach(wordTreeItem => 
    fetchresult.data.items.forEach((wordTreeItem, i) => {
      wtData.push([wordTreeItem.phrase, `${i}`])
      interactive_table[i] = wordTreeItem.target;//Set value pair
    });

    if (globals.parameters.debugging) {
      console.log("wtData =>", wtData)
      console.log("color_guid_matching_table =>", interactive_table)
    }

    setSubjectTreeData(wtData);
    setInteractiveTable(interactive_table)
  };


  const options = {
    wordtree: {
        title:"Généalogie de la Bible",
        format: "implicit",
        word: `${subjectSource}`,
        enableInteractivity: false,
        tooltip:{isHtml: false},
        legend: 'Généalogie de la Bible',
        showTooltip: false,
        showInfoWindow: false
    },
  };

  const chartEvents = [
    {
      callback: ({ chartWrapper, google }) => {
        const chart = chartWrapper.getChart();
        //chart.container.addEventListener("click", (ev) => console.log(ev))
        chart.container.addEventListener("click", (ev) => { console.log(ev); return false; })
      },
      eventName: "ready"
    }
    ];  

  //-------------------------------------------------------------------------------

  //-------------------------------------------------------------------------------
  const selectEventHandler = (pchartWrapper) => {
    console.log("---------------------------------------->SELECT")
    console.log("pchartWrapper => ", pchartWrapper);
    console.log("chartWrapper.Dw (InteractiveTable) => ", pchartWrapper.Dw);
    console.log("Chart container id => ", pchartWrapper.container);
    console.log("pchartWrapper.getselection => ",pchartWrapper.getSelection());

    //Handle new subject selection
    let selectedWordTreeNode = pchartWrapper.getSelection();
    console.log("selectedWordTreeNode => ", selectedWordTreeNode)
    if (interactiveTable && selectedWordTreeNode && selectedWordTreeNode.color){
      let selected_color = selectedWordTreeNode.color;
      let selected_list_no_fait = interactiveTable[selected_color];//Check translation table from color to no_list_fait
      if (selected_list_no_fait){
        console.log("selected_list_no_fait:", selected_list_no_fait);
        history.push(`/wordtree/${selected_list_no_fait}`);//Jump to next selected subject
      }
    }
  }

    /* Function component Rendering */
    return (
    <div style={{BackgroundColor:"red", Overflow:"scroll"}}>
            <Chart
            enableInteractivity={false}
            chartType="WordTree"
            width="1200px"
            height="400px"
            data={subjectTreeData}
            options={options}
            chartEvents_ok={chartEvents}
            chartEvents={[
                {
                  eventName: "ready",
                  //callback: ({ chartWrapper, google, props, eventArgs }) => {
                  callback: ({ chartWrapper, google, props }) => {
                    console.log("---------------------------------------->READY")
                      console.log("chartWrapper->", chartWrapper)
                      console.log("props->", props)
                      //console.log("eventArgs->", eventArgs)
                    if (interactiveTable){
                      chartWrapper.setQuery(interactiveTable);
                      //return;
                    } 
                      //chartWrapper.setQuery("Patrick");
                      
                    console.log("interactiveTable >>", interactiveTable)
                    const chart = chartWrapper.getChart();
                    const chartName = chartWrapper.getChartName();
                    const dataTable = chartWrapper.getDataTable();
                    const chartType = chartWrapper.getChartType();
                    const chartQuery = chartWrapper.getQuery();
                    
                    //chart.container.addEventListener("click", (ev) => console.log(ev));//runs

                    console.log("chartWrapper  =>", chartWrapper);
                    console.log("chart  =>", chart);
                    console.log("chartName  =>", chartName);
                    console.log("chartType  =>", chartType);
                    console.log("chartdataTable  =>", dataTable);
                    //console.log("chartQuery  =>", chartQuery);

                    //Add wortree attributes to verbs elements
                    //------------------ Parse WordTree items
                    var WordTreeNodes = document.querySelectorAll("text");

                    console.log("WordTreeNodes ->", WordTreeNodes)

                    WordTreeNodes.forEach(wordTreeItem => {
                        console.log("wordTreeItem ->", wordTreeItem)
                        console.log("wordTreeItem ->", wordTreeItem.textContent)

                      if (
                          (wordTreeItem.textContent == "fils")
                          ||(wordTreeItem.textContent == subjectSource) //Disable subject source link 
                          ||(wordTreeItem.textContent == "fille")
                          ||(wordTreeItem.textContent == "de")
                          ||(wordTreeItem.textContent == "frère")
                          ||(wordTreeItem.textContent == "soeur")
                          ||(wordTreeItem.textContent == "mère")
                          ||(wordTreeItem.textContent == "père")
                        ){
                        wordTreeItem.setAttribute("wordtree","0")
                      }
                      else{
                        wordTreeItem.setAttribute("wordtree","1")
                      }
                    });

                    //Add select event to listener
                    //google.visualization.events.addListener(chart, 'select', function (chartWrapper) {
                    google.visualization.events.addListener(chartWrapper, 'select', selectEventHandler);
                  }
                }]} />
      </div>);
}

export default WordTree;