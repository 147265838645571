import React, {Component} from 'react';
import axios from 'axios';
import "./Precede.css";
import Bible from '../Bible/Bible';
import SubjectButton from '../SubjectButton/SubjectButton';
import SubjectEmphasis from '../SubjectEmphasis/SubjectEmphasis';
import {globals} from '../Globals/Globals';

class Precede extends Component {
    constructor(props){
        super(props);
        this.state = {subjectDataItems : null};
    }

    fetchSubjectData = async (next_list_no_fait) =>{
        const request = `${globals.subject_data_service_precede_endpoint.url}?list_no_fait=${next_list_no_fait}`;
        const fetchSubjectData = await axios.get(request);//Call subject-data-service
        this.setState({subjectDataItems: fetchSubjectData.data});
    }

    componentDidMount = () => {
        this.fetchSubjectData(this.props.subject.list_no_fait);
    }

    componentDidUpdate(prevProps) {
        // Typical usage (don't forget to compare props):
        if (this.props.subject !== prevProps.subject) {
          this.fetchSubjectData(this.props.subject.list_no_fait);
        }
    }

    render(){
        if (this.state.subjectDataItems === null || this.state.subjectDataItems.length === 0)
            return(<span data-tag="succede"></span>);
        else
            return(
                <span data-tag="succede">
                    {
                    this.state.subjectDataItems.map(
                        subjectData => {
                            return (
                                    <div key="{subjectData.list_no_fait}">
                                        <SubjectEmphasis text={this.props.subject}/>
                                        <span class="light-italic">&nbsp;succède&nbsp;à&nbsp;</span>
                                        {subjectData.precedent &&
                                        <SubjectButton list_no_fait={subjectData.precedent} text={subjectData._precedent} handler={this.props.handler}></SubjectButton>
                                        }
                                        {subjectData.bible &&
                                        <Bible bibleref={subjectData.bible}/>
                                        }
                                    </div>
                            );
                        }
                    )}
                </span>);
    }
};   

export default Precede;