import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import axios from 'axios';
import InputForm from '../InputForm/InputForm';
import './ModalReportError.css'

class ModalReportError extends React.Component {
  constructor(props) {
    super(props);

    this.clearObject = {
      nom:"",
      prenom:"",
      email:"",
      message:"",
      err_nom:[], err_prenom:[], err_email:[], err_message:[], err_captcha:[],
      has_changed:false,
      submitted:false,
      captcha:"",
      modal: false
    }

    this.state = this.clearObject;

    this.toggle = this.toggle.bind(this);
  }

  componentDidMount = () => {
    this.clearForm()
    this.handle_nom = this.handle_nom.bind(this)
    this.handle_prenom = this.handle_prenom.bind(this)
    this.handle_email = this.handle_email.bind(this)
    this.handle_message = this.handle_message.bind(this)
    this.handle_captcha = this.handle_captcha.bind(this)
  }

  onSubmitSignalMessageFormHandler  = async event => {
    //event.preventDefault();

    //Force all fields check
    let canSubmit = this.checkValidation('nom')
    canSubmit &= this.checkValidation('prenom')
    canSubmit &= this.checkValidation('email')
    canSubmit &= this.checkValidation('message')
    canSubmit &= this.checkValidation('captcha')

    console.log("canSubmit:", canSubmit)

    if (!canSubmit) return false;

    const localURL = 'http://localhost:4006'
    const remoteURL = 'https://neuronal-contact-service.neuronal.bible'
    let service_db_url = ["localhost", "127.0.0.1"].includes(window.location.hostname)?localURL:remoteURL

    console.log("micro-service => service_db_url:", service_db_url," this.state =>", this.state);

    //call requete-service
    var serviceResult = null
    try{
        axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
        serviceResult = await axios.post(`${service_db_url}/add-contact`,
        this.state);
    } catch(err){
        alert(`Verifiez votre connexion wifi : ${err}`);
        return;
    }

    //Get service aknowledge before resetting state values.
    console.log("add-contact returns :", serviceResult.data);

    if (serviceResult.data.state != true){
        alert("Erreur:" + serviceResult.data.message);
    }
    else
    try{
            //Post confirmation e-mail back to user
            const mailResult = await axios.post(`${service_db_url}/acknowledge-signal`,
                {
                    from:"ne-pas-repondre@neuronal.bible",
                    email:this.state.email,
                    subject:"Accusé de réception de votre message",
                    prenom:this.state.prenom,
                    nom:this.state.nom,
                    ccopy:"pmabiala@me.com",
                    no_list_fait:this.props.subject.list_no_fait,
                    personnage:this.props.subject.nom
                }
            );

            console.log("mailResult =>", mailResult);
            //Display confirmation number
            alert(`Un courriel de confirmation vous a été envoyé`)

            //Clear form
            this.clearForm()
    } catch(err){
        alert(`Une erreur est survenue :${err}`);
        return;
    }
  }

  checkValidation = (fieldName, read_only = false) => {
      let max = 45
      let min = 2
      
      let errorsFieldName = `err_${fieldName}`
      let nextState = this.state
      let nextErrors = this.state[errorsFieldName]
      nextErrors = []

      switch (fieldName) {
          case "nom" :     
              min = 0           
              max = 45
              
              if (this.state[fieldName].trim().length < min)
                  nextErrors.push(`trop court.`)

              if (this.state[fieldName].length > max)
                  nextErrors.push(`trop long, ${this.state[fieldName].length - max} caractères en trop.`)
              break

          case "prenom" :
              min = 0
              max = 45

              if (this.state[fieldName].trim().length < min)
                  nextErrors.push(`trop court.`)

              if (this.state[fieldName].length > max)
                  nextErrors.push(`trop long, ${this.state[fieldName].length - max} caractères en trop.`)
              break

          case "email" :
              min = 5
              max = 45

              if (this.state[fieldName].trim().length < min)
                  nextErrors.push(`trop court.`)

              if (this.state[fieldName].length > max)
                  nextErrors.push(`trop long, ${this.state[fieldName].length - max} caractères en trop.`)

              if (!this.state[fieldName].match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i))
                  nextErrors.push(`non valide`)
              break

          case "message" :
              min = 2
              max = 145

              if (this.state[fieldName].trim().length < min)
                  nextErrors.push(`trop court.`)

              if (this.state[fieldName].length > max)
                  nextErrors.push(`trop long, ${this.state[fieldName].length - max} caractères en trop.`)
              break

          case "captcha" :
              min = 1
              max = 2

              if (this.state[fieldName].trim().length < min)
                  nextErrors.push(`trop court.`)

              if (this.state[fieldName].length > max)
                  nextErrors.push(`trop long, ${this.state[fieldName].length - max} caractères en trop.`)
              
              if (parseInt(this.state[fieldName]) !== (this.state.v1 + this.state.v2))
                  nextErrors.push(`calcul faux !`)
              break
      }

      //update error field
      nextState[errorsFieldName] = nextErrors

      //set change flag
      nextState.has_changed = true

      //update internal state
      if (!read_only) //Avoid recursive setstate
          this.setState(nextState)

      //Return validation predicate
      return (nextErrors.length === 0) //returns true if no error occurs
  }

  handle_nom = e => {
      e.preventDefault();

      this.setState({nom:e.target.value}, ()=>{this.checkValidation(e.target.name)})
  }

  handle_prenom = e => {
      e.preventDefault();

      this.setState({prenom:e.target.value}, ()=>{this.checkValidation(e.target.name)})
  }

  handle_email = e => {
      e.preventDefault();

      this.setState({email:e.target.value}, ()=>{this.checkValidation(e.target.name)})
  }

  handle_message = e => {
      e.preventDefault();

      this.setState({message:e.target.value}, ()=>{this.checkValidation(e.target.name)})
  }

  handle_captcha = e => {
      e.preventDefault();

      this.setState({captcha:e.target.value}, ()=>{this.checkValidation(e.target.name)})
  }

  randomize = (min, max) => {
      return Math.floor(min + Math.random() * (max - min));
  }

  clearForm = () => {
      this.clearObject = Object.assign(this.clearObject, {v1:this.randomize(0,5), v2:this.randomize(0,5)})
      this.setState(this.clearObject, ()=>{console.log("state cleaned:", this.state)});
  }

  validateForm  = () =>{
      return (
          this.state.has_changed
          && this.checkValidation('nom', true)
          && this.checkValidation('prenom', true)
          && this.checkValidation('email', true)
          && this.checkValidation('message', true)
          && this.checkValidation('captcha', true)
      )
  }

  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal
    }));
  }

  render() {
    const externalCloseBtn = <button className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={this.toggle}>&times;</button>;
    return (
      <div className="inline-container">
        <Button className="share mail-alert" onClick={()=>{this.toggle()}}>Signaler</Button>
        {/*
        <span 
            id="plus-menu" 
            className="fas fa-exclamation CursorPointer"
            title="Signaler une erreur à propos de {this.props.subject.nom}"
            onClick={()=>{this.toggle()}}><span className="tooltiptext">&nbsp;Signaler une erreur</span></span>
        */}
        <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className} external={externalCloseBtn}>
          <ModalHeader className="mail-alert">Signaler une erreur</ModalHeader>
          <ModalBody>
            <h5>{this.props.subject.nom}</h5>
            
            <q style={{textAlign:"justify"}}>Vous avez découvert une erreur ou une imprecision dans le résultat retourné par <i>neuronal.bible</i> à propos de <strong>{this.props.subject.nom}</strong>, merci de nous la signaler. Nous procéderons à la correction dans la mesure du possible.</q>
            <br/>
            <br/>
            <form id="contact-form" onSubmit={this.onSubmitFormHandler}>
                <InputForm 
                    label={"Nom"} 
                    name={"nom"} 
                    value={this.state.nom} 
                    onChange={this.handle_nom} 
                    type={"text"} 
                    placeholder={"Votre nom"}
                    error={this.state.err_nom}
                />

                <InputForm 
                    label={"Prénom"} 
                    name={"prenom"} 
                    value={this.state.prenom} 
                    onChange={this.handle_prenom} 
                    type={"text"} 
                    placeholder={"Votre prénom"}
                    error={this.state.err_prenom}
                />

                <InputForm 
                    label={"email"} 
                    name={"email"} 
                    value={this.state.email} 
                    onChange={this.handle_email} 
                    type={"text"} 
                    placeholder={"Votre adresse e-mail"}
                    error={this.state.err_email}
                />

                <InputForm 
                    label={"Message"} 
                    name={"message"} 
                    value={this.state.message} 
                    onChange={this.handle_message} 
                    type={"textarea"} 
                    placeholder={"Votre message"}
                    error={this.state.err_message}
                />

                <InputForm 
                    label={`Je ne suis pas un robot, je calcule: ${this.state.v1} + ${this.state.v2}`} 
                    name={"captcha"} 
                    value={this.state.captcha} 
                    onChange={this.handle_captcha} 
                    type={"text"} 
                    placeholder={`Combien font ${this.state.v1} + ${this.state.v2} ?`}
                    error={this.state.err_captcha}
                />
            </form>            
          </ModalBody>

          <ModalFooter>
            <Button color={this.validateForm()?"primary":"danger"} onClick={()=>{this.onSubmitSignalMessageFormHandler()}}>{this.validateForm()?"Signaler une erreur":"Remplir le formulaire !"}</Button>
            <Button color="secondary" onClick={this.toggle}>Annuler</Button>{' '}
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default ModalReportError;