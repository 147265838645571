import React, {Component} from 'react';
import axios from 'axios';
import "./Prophete.css";
import Bible from '../Bible/Bible';
import SubjectButton from '../SubjectButton/SubjectButton';
import SubjectEmphasis from '../SubjectEmphasis/SubjectEmphasis';

class Prophete extends Component {
    constructor(props){
        super(props);
        this.state = {subjectDataItems : null};
    }

    fetchSubjectData = async (next_list_no_fait) =>{
        const request = `https://subject-data-service.neuronal.bible/prophete?list_no_fait=${next_list_no_fait}`;
        const fetchSubjectData = await axios.get(request);//Call subject-data-service
        this.setState({subjectDataItems: fetchSubjectData.data});
    }

    componentDidMount = () => {
        this.fetchSubjectData(this.props.subject.list_no_fait);
    }

    componentDidUpdate(prevProps) {
        // Typical usage (don't forget to compare props):
        if (this.props.subject !== prevProps.subject) {
          this.fetchSubjectData(this.props.subject.list_no_fait);
        }
    }

    render(){
        if (this.state.subjectDataItems === null || this.state.subjectDataItems.length === 0)
            return(<span data-tag="prophete"></span>);
        else
            return(
                <span data-tag="prophete">
                    {
                    this.state.subjectDataItems.map(
                        subjectData => {
                            return (
                                    <div key={subjectData.list_no_fait}>
                                        <SubjectEmphasis text={this.props.subject}/>
                                        {/* Le sujet sélectionné est le prophète et non le roi */}
                                        {(subjectData.le_sujet_est_prophete_flag == '1') &&
                                            <span>
                                                {(subjectData._sexe_sujet === 'H') && <span class="light-italic">&nbsp;a été prophète</span>}
                                                {(subjectData._sexe_sujet === 'F') && <span class="light-italic">&nbsp;a été prophètesse</span>}
                                                {/* Le roi contemporain de ce prophète est connu */}
                                                {subjectData.roi &&
                                                    <span>
                                                        {(subjectData._sexe_roi === 'H') && <span class="light-italic">&nbsp;du&nbsp;roi&nbsp;</span>}
                                                        {(subjectData._sexe_roi === 'F') && <span class="light-italic">&nbsp;de&nbsp;la reine&nbsp;</span>}
                                                        <SubjectButton list_no_fait={subjectData.roi} text={subjectData._roi} handler={this.props.handler}/>
                                                    </span>
                                                }
                                            </span>
                                        }

                                        {((subjectData.le_sujet_est_roi_flag == '1') && subjectData.sujet) &&
                                            <span>
                                                {(subjectData._sexe_sujet === 'H') && <span class="light-italic">&nbsp;a&nbsp;eut&nbsp;pour&nbsp;prophète&nbsp;</span>}
                                                {(subjectData._sexe_sujet === 'F') && <span class="light-italic">&nbsp;a&nbsp;eut&nbsp;pour&nbsp;prophètesse&nbsp;</span>}
                                                <SubjectButton list_no_fait={subjectData.sujet} text={subjectData._sujet} handler={this.props.handler}/>
                                                <span> durant son règne.</span>
                                            </span>
                                        }
                                        
                                        {/* Bible references */}
                                        {subjectData.bible &&
                                        <span>
                                            <Bible bibleref={subjectData.bible}/>
                                        </span>
                                        }
                                    </div>
                            );
                        }
                    )}
                </span>);
    }
};   

export default Prophete;