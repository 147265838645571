import React, {Component} from 'react';
import axios from 'axios';
import "./Juge.css";
import Bible from '../Bible/Bible';
import SubjectEmphasis from '../SubjectEmphasis/SubjectEmphasis'
import SubjectButton from "../SubjectButton/SubjectButton"

const Ordinal = (props) => {
    return(
        <>
            {(props.rank == 1) && <>
                1<sup>er</sup>
            </>}
            {(props.rank > 1) && <>
                {props.rank}<sup>ème</sup>
            </>}
        </>
    )
}

class Juge extends Component {
    constructor(props){
        super(props);
        this.state = {subjectData : null};
    }

    fetchSubjectData = async (next_list_no_fait) =>{
        const request = `https://subject-data-service.neuronal.bible/juge?list_no_fait=${next_list_no_fait}`;
        const fetchSubjectData = await axios.get(request);//Call subject-data-service
        this.setState({subjectData: fetchSubjectData.data});
    }

    componentDidMount = () => {
        this.fetchSubjectData(this.props.subject.list_no_fait);
    }

    componentDidUpdate(prevProps) {
        // Typical usage (don't forget to compare props):
        if (this.props.subject !== prevProps.subject) {
          this.fetchSubjectData(this.props.subject.list_no_fait);
        }
    }

    render(){
        if (this.state.subjectData == null || this.state.subjectData.length === 0)
            return(<span data-tag="juge"></span>);
        else
            return(
                <span data-tag="juge">
                    <SubjectEmphasis text={this.state.subjectData}/>
                    <Ordinal rank={this.state.subjectData.appel}/>
                    <span>&nbsp;juge&nbsp;en Israël&nbsp;</span>
                    {this.state.subjectData.predecesseur && 
                        <>&nbsp;après&nbsp;<SubjectButton list_no_fait={this.state.subjectData.predecesseur} text={this.state.subjectData._predecesseur} handler={this.props.handler}></SubjectButton></>
                    }
                    {this.state.subjectData.regne &&
                        <span>&nbsp;pendant&nbsp;{this.state.subjectData.regne}&nbsp;{this.state.subjectData.unite_regne}</span>
                    }
                    <Bible bibleref={this.state.subjectData.bible}/>
                </span>);
    }
};   

export default Juge;