import React, {Component} from 'react';
import axios from 'axios';
import "./Mort.css";
import Bible from '../Bible/Bible';

class Mort extends Component {
    constructor(props){
        super(props);
        this.state = {subjectData : null};
    }

    fetchSubjectData = async (next_list_no_fait) =>{
        const request = `https://subject-data-service.neuronal.bible/Mort?list_no_fait=${next_list_no_fait}`;
        const fetchSubjectData = await axios.get(request);//Call subject-data-service
        this.setState({subjectData: fetchSubjectData.data});
    }

    componentDidMount = () => {
        this.fetchSubjectData(this.props.subject.list_no_fait);
    }

    componentDidUpdate(prevProps) {
        // Typical usage (don't forget to compare props):
        if (this.props.subject !== prevProps.subject) {
          this.fetchSubjectData(this.props.subject.list_no_fait);
        }
    }
      
    render(){
        if (this.state.subjectData == null)
            return(<span data-tag="mort"></span>);
        else
            return( 
            <div data-tag="mort">
                

                {this.state.subjectData.bible &&
                    <span>
                        {(this.props.subject.sexe === 'H') &&
                            <span class="light-italic">décédé&nbsp;</span>}
                        {(this.props.subject.sexe === 'F') &&
                            <span class="light-italic">décédée&nbsp;</span>}

                        {this.state.subjectData.longevite &&
                            <span>&nbsp;à&nbsp;l'age de&nbsp;{this.state.subjectData.longevite}&nbsp;ans</span>
                        }
                        <span>&nbsp;selon&nbsp;</span>
                        <Bible bibleref={this.state.subjectData.bible}/>
                    </span>
                }
            </div>);
    }
};   

export default Mort;