import React, { Component, useEffect, useState } from 'react';
import {useParams} from "react-router-dom"
import axios from 'axios';
import Introduction from '../Introduction/Introduction';
import './Subject.css';

import Tue from '../Tue/Tue';
import Mort from '../Mort/Mort';
import Apotre from '../Apotre/Apotre';
import Enfant from '../Enfant/Enfant';
import Chefdetroupe from '../Chefdetroupe/Chefdetroupe';
import Roi from '../Roi/Roi';
import Succede from '../Succede/Succede';
import Prophete from '../Prophete/Prophete';
import Contemporain from '../Contemporain/Contemporain';
import Descendant from '../Descendant/Descendant';
import Histoire from '../Histoire/Histoire';
import Disciple from '../Disciple/Disciple';
import Juge from '../Juge/Juge';
import Fraterie from '../Fraterie/Fraterie';
import SubjectAliases from '../SubjectAliases/SubjectAliases';
import Epoux from '../Epoux/Epoux';

import Precede from '../Precede/Precede';
import PetitFils from '../PetitFils/PetitFils';
import Cousin from '../Cousin/Cousin';
import Adversaire from '../Adversaire/Adversaire';

import { globals } from '../Globals/Globals';

const Subject = (props) => {
    let [subjectData, setSubjectData] = useState(null)
    let [prevNoFait, setPrevNoFait] = useState(null)
    let { no_list_fait } = useParams();
    console.log("useParams() from Subject component", no_list_fait)

    useEffect(()=>{
        //Detect no_list_fait didchange
        if (globals.parameters.debugging){
            console.clear()
            console.log("no_list_fait:", no_list_fait)
            console.log("subjectData:", subjectData)       
        }

        if ((no_list_fait != prevNoFait) && (no_list_fait != null)){
            setPrevNoFait(no_list_fait)
            fetchSelectedSubject(no_list_fait)
        }
    })

    const fetchSelectedSubject = async (selected_list_no_fait) =>{
        //Search for selected subject
        const request = `https://subject-data-service.neuronal.bible/subject?list_no_fait=${selected_list_no_fait}`
        const fetchSelectedSubject = await axios.get(request);
        console.log("fetchSelectedSubject:", fetchSelectedSubject.data);
        setSubjectData(fetchSelectedSubject.data);
    }

    //rendering
    if (subjectData == null)
    return(<p></p>)
    else
        return(
            <div class="card">
                <SubjectAliases subject = {subjectData}/>
                <Introduction subject = {subjectData}/>
            
                <Disciple subject = {subjectData}/>
                <Tue subject = {subjectData}/>
                <Mort subject = {subjectData}/>
                <Juge subject = {subjectData}/>
                <Chefdetroupe subject = {subjectData}/>
                <Apotre subject = {subjectData} errCallback={props.errCallback}/>

                {/* - Common subject life - */}
                <Epoux subject = {subjectData} errCallback={props.errCallback}/>
                <Enfant subject = {subjectData}/>
                
                <Fraterie subject = {subjectData}/>
                <PetitFils subject = {subjectData}/>
                <Cousin subject = {subjectData}/>

                <Contemporain subject = {subjectData}/>
                {globals.parameters.local &&
                <Descendant subject = {subjectData}/>}

                {/* - Subject reign part - */}
                <Roi subject = {subjectData}/>

                {globals.parameters.local &&
                <Adversaire subject = {subjectData}/>} 
                       
                <Succede subject = {subjectData}/>

                {globals.parameters.local &&
                <Precede subject = {subjectData}/>}
                <Prophete subject = {subjectData}/>
                <Histoire subject = {subjectData}/>
            </div>
        );
};

export default  Subject;