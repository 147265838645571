import React, {Component} from 'react';
import axios from 'axios';
import "./Histoire.css";
import Bible from '../Bible/Bible';
import SubjectButton from '../SubjectButton/SubjectButton';
import SubjectEmphasis from '../SubjectEmphasis/SubjectEmphasis'

class Histoire extends Component {
    constructor(props){
        super(props);
        this.state = {subjectDataItems : null};
    }

    fetchSubjectData = async (next_list_no_fait) =>{
        const request = `https://subject-data-service.neuronal.bible/histoire?list_no_fait=${next_list_no_fait}`;
        const fetchSubjectData = await axios.get(request);//Call subject-data-service
        this.setState({subjectDataItems: fetchSubjectData.data});
    }

    componentDidMount = () => {
        this.fetchSubjectData(this.props.subject.list_no_fait);
    }

    componentDidUpdate(prevProps) {
        // Typical usage (don't forget to compare props):
        if (this.props.subject !== prevProps.subject) {
          this.fetchSubjectData(this.props.subject.list_no_fait);
        }
    }

    render(){
        if (this.state.subjectDataItems === null || this.state.subjectDataItems.length === 0)
            return(<span data-tag="Histoire"></span>);
        else
            return(
                <span data-tag="Histoire">
                    {
                    this.state.subjectDataItems.map(
                        subjectData => {
                            return (
                                    <div>
                                        {/*Disabled on 2021-07-17 too ugly
                                        <SubjectEmphasis text={this.props.subject}/>
                                            {subjectData._sujet_1 &&
                                                <span>
                                                    ,&nbsp;<SubjectButton list_no_fait={subjectData.sujet_1} text={subjectData._sujet_1} handler={this.props.handler}></SubjectButton>  
                                                </span>}

                                            {subjectData._sujet_2 &&
                                                <span>
                                                    ,&nbsp;<SubjectButton list_no_fait={subjectData.sujet_2} text={subjectData._sujet_2} handler={this.props.handler}></SubjectButton>   
                                                </span>}

                                            <span class="light-italic">&nbsp;se&nbsp;sont croisés&nbsp;</span>
                                        */}
                                        {subjectData.chronologie &&
                                            <span>
                                                <span class="fa fa-history" title="Histoire"/>&nbsp;
                                                <span>la&nbsp;{subjectData.chronologie_annee}<span class="upper">{(subjectData.chronologie_annee == 1)?"ère":"ème"}</span>&nbsp;année&nbsp;de&nbsp;</span>
                                                <SubjectButton list_no_fait={subjectData.chronologie_roi} text={subjectData._chronologie_roi} handler={this.props.handler}></SubjectButton>
                                            </span>}
                                            
                                        {/*
                                        <strong>Ajouter le RECIT</strong>
                                        
                                            Add a detail button in order to display a popup comment
                                            {subjectData.list_commentaire &&
                                                <p>{subjectData.list_commentaire}</p>
                                            }
                                         */}
                                         {subjectData.list_commentaire &&
                                                <span>, {subjectData.list_commentaire}</span>
                                         }

                                        {subjectData.bible &&
                                        <span>
                                            <Bible bibleref={subjectData.bible}/>
                                        </span>}
                                    </div>
                            );
                        }
                    )}
                </span>);
    }
};   

export default Histoire;