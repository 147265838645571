import React from 'react';
import './Neuronal.css'

export default () => {
    return (
        <div className="top-bar">
            <h1>neuronal.bible</h1>
            <h4 style={{marginTop:"6px"}}>
                Réseau neuronal dédié à la <strong><i>Sainte</i> Bible</strong>
            </h4>
        </div>
    )
};