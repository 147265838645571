import React, {Component} from 'react';
import axios from 'axios';
import "./Apotre.css";
import Bible from '../Bible/Bible';

class Apotre extends Component {
    constructor(props){
        super(props);
        this.state = {subjectData : null};
    }

    fetchSubjectData = async (next_list_no_fait) =>{
        const request = `https://subject-data-service.neuronal.bible/apotre?list_no_fait=${next_list_no_fait}`;
        const fetchSubjectData = await axios.get(request);//Call subject-data-service
        this.setState({subjectData: fetchSubjectData.data});
    }

    componentDidMount = () => {
        this.fetchSubjectData(this.props.subject.list_no_fait);
    }

    componentDidUpdate(prevProps) {
        // Typical usage (don't forget to compare props):
        if (this.props.subject !== prevProps.subject) {
          this.fetchSubjectData(this.props.subject.list_no_fait);
        }
    }
      
    render(){
        if (this.state.subjectData == null)
            return(<span data-tag="apotre"></span>);
        else
            return(
            <div data-tag="apotre">
                {/*<span>apôtre&nbsp;n°&nbsp;{this.state.subjectData.appel}&nbsp;de Jésus</span>*/}
                {this.state.subjectData.list_commentaire &&
                    <span class="account">{this.state.subjectData.list_commentaire}</span>
                }

                {this.state.subjectData.bible &&
                    <span>
                        <Bible bibleref={this.state.subjectData.bible}/>
                    </span>
                }
            </div>);
    }
};   

export default Apotre;