import React, {Component} from 'react';
import axios from 'axios';
import "./Descendant.css";
import Bible from '../Bible/Bible';
import SubjectButton from '../SubjectButton/SubjectButton';
import SubjectEmphasis from '../SubjectEmphasis/SubjectEmphasis';

class Descendant extends Component {
    constructor(props){
        super(props);
        this.state = {subjectDataItems : null};
    }

    fetchSubjectData = async (next_list_no_fait) =>{
        const request = `https://subject-data-service.neuronal.bible/descendant?list_no_fait=${next_list_no_fait}`;
        const fetchSubjectData = await axios.get(request);//Call subject-data-service
        this.setState({subjectDataItems: fetchSubjectData.data});
    }

    componentDidMount = () => {
        this.fetchSubjectData(this.props.subject.list_no_fait);
    }

    componentDidUpdate(prevProps) {
        // Typical usage (don't forget to compare props):
        if (this.props.subject !== prevProps.subject) {
          this.fetchSubjectData(this.props.subject.list_no_fait);
        }
    }

    render(){
        if (this.state.subjectDataItems === null || this.state.subjectDataItems.length === 0)
            return(<span data-tag="descendant"></span>);
        else
            return(
                <span data-tag="descendant">
                    {
                    this.state.subjectDataItems.map(
                        subjectData => {
                            return (
                                    <div>
                                        <SubjectEmphasis text={this.props.subject}/>
                                        {(this.props.subject.sexe === 'H') &&
                                            <span class="light-italic">&nbsp;descendant&nbsp;de&nbsp;</span>}
                                        {(this.props.subject.sexe === 'F') &&
                                            <span class="light-italic">&nbsp;descendante&nbsp;de&nbsp;</span>}

                                        <SubjectButton list_no_fait={subjectData.descendant} text={subjectData._descendant} handler={this.props.handler}></SubjectButton>

                                        {subjectData.bible &&
                                        <span>
                                            <Bible bibleref={subjectData.bible}/>
                                        </span>
                                        }
                                    </div>
                            );
                        }
                    )}
                </span>);
    }
};   

export default Descendant;