const production = true;
const local = !production;

const globals = {
    parameters : {
            debugging : false, 
            history_debugging:true,
            graph_enabled:false,
            local:local
        },
    list_service_subjects_endpoint : {
        ...(local && {url : 'http://localhost:4001/subjects'}),
        ...(production && {url : 'https://list-service.neuronal.bible/subjects'})
    },
    list_service_subject_word_tree_endpoint : {
        ...(local && {url : 'http://localhost:4001/subject-word-tree'}),
        ...(production && {url : 'https://list-service.neuronal.bible/subject-word-tree'})
    },
    subject_data_service_cousin_endpoint : {
        ...(local && {url : 'http://localhost:4002/cousin'}),
        ...(production && {url : 'https://subject-data-service.neuronal.bible/cousin'})
    },
    subject_data_service_precede_endpoint : {
        ...(local && {url : 'http://localhost:4002/precede'}),
        ...(production && {url : 'https://subject-data-service.neuronal.bible/precede'})
    },
    subject_data_service_petitfils_endpoint : {
        ...(local && {url : 'http://localhost:4002/petitfils'}),
        ...(production && {url : 'https://subject-data-service.neuronal.bible/petitfils'})
    },
    subject_data_service_adversaire_endpoint : {
        ...(local && {url : 'http://localhost:4002/adversaire'}),
        ...(production && {url : 'https://subject-data-service.neuronal.bible/adversaire'})
    }
}

export {globals}