import React, {Component} from 'react';
import axios from 'axios';
import "./Adversaire.css";
import Bible from '../Bible/Bible';
import SubjectButton from '../SubjectButton/SubjectButton';
import SubjectEmphasis from '../SubjectEmphasis/SubjectEmphasis';
import {globals} from '../Globals/Globals';

class Adversaire extends Component {
    constructor(props){
        super(props);
        this.state = {subjectDataItems : null};
    }

    fetchSubjectData = async (next_list_no_fait) =>{
        const request = `${globals.subject_data_service_adversaire_endpoint}?list_no_fait=${next_list_no_fait}`;
        const fetchSubjectData = await axios.get(request);//Call subject-data-service
        this.setState({subjectDataItems: fetchSubjectData.data});
    }

    componentDidMount = () => {
        this.fetchSubjectData(this.props.subject.list_no_fait);
    }

    componentDidUpdate(prevProps) {
        // Typical usage (don't forget to compare props):
        if (this.props.subject !== prevProps.subject) {
          this.fetchSubjectData(this.props.subject.list_no_fait);
        }
    }
      
    render(){
        if (this.state.subjectDataItems === null || this.state.subjectDataItems.length === 0)
            return(<span data-tag="enfants"></span>);
        else
            return(
                <div data-tag="enfants" class="verbs" key="{subjectData.list_no_fait}">{
                    this.state.subjectDataItems.map(
                        subjectData => {
                            console.clear();
                            console.log("Enfants-subjectData =>", subjectData);
                            return(
                                <div key={subjectData.list_no_fait}>
                                    {subjectData._pere_flag &&
                                        <span>
                                            <SubjectEmphasis text={this.props.subject}/>
                                            <span class="light-italic">&nbsp;père de&nbsp;</span>
                                            <SubjectButton list_no_fait={subjectData.enfant} text={subjectData._enfant} handler={this.props.handler}></SubjectButton>
                                        
                                            {subjectData.mere &&
                                                <span>
                                                    <span class="light-italic">&nbsp;qu'il a eu avec&nbsp;</span>
                                                    <SubjectButton list_no_fait={subjectData.mere} text={subjectData._mere} handler={this.props.handler}></SubjectButton>
                                                </span>
                                            }
                                        
                                        {subjectData.bible &&
                                                    <span>
                                                        <Bible bibleref={subjectData.bible}/>
                                                    </span>
                                        }
                                        </span>
                                    }

                                    {subjectData._mere_flag &&
                                        <span>
                                            {subjectData.mere &&
                                                <span>
                                                    <SubjectEmphasis text={this.props.subject}/>
                                                    <span class="light-italic">&nbsp;mère de&nbsp;</span>
                                                </span>
                                            }
                                            <SubjectButton list_no_fait={subjectData.enfant} text={subjectData._enfant} handler={this.props.handler}></SubjectButton>
                                            <span class="light-italic">&nbsp;qu'elle a eu avec&nbsp;</span>

                                            <SubjectButton list_no_fait={subjectData.pere} text={subjectData._pere} handler={this.props.handler}></SubjectButton>
                                            <span>
                                            
                                            </span>
                                            {subjectData.bible &&
                                                    <span>
                                                        <Bible bibleref={subjectData.bible}/>
                                                    </span>
                                            }
                                        </span>
                                    }

                                    {subjectData._enfant_flag &&
                                        <span>
                                        <SubjectEmphasis text={this.props.subject}/>
                                        {(this.props.subject.sexe === 'H') && <span class="light-italic">&nbsp;fils de&nbsp;</span>}
                                        {(this.props.subject.sexe === 'F') && <span class="light-italic">&nbsp;fille de&nbsp;</span>}

                                        <SubjectButton list_no_fait={subjectData.pere} text={subjectData._pere} handler={this.props.handler}></SubjectButton>

                                        {subjectData.mere &&
                                            <span>
                                                <span class="light-italic">&nbsp;et&nbsp;</span>
                                                <SubjectButton list_no_fait={subjectData.mere} text={subjectData._mere} handler={this.props.handler}></SubjectButton>
                                            </span>
                                        }

                                        {subjectData.bible &&
                                                    <span>
                                                        <Bible bibleref={subjectData.bible}/>
                                                    </span>
                                        }
                                        </span>
                                    }
                                </div>);
                            })}
                </div>);
    }
};   

export default Adversaire;