import React from "react";
import {Button} from 'reactstrap';
import ModalReportError from "../ModalReportError/ModalReportError";
import './SocialShare.css';

export default function SocialShare (props) {
    const url = `https://neuronal.bible/sujet/${props.subject.list_no_fait}`;

    var popupCenter = function(url, title, width, height){
        var popupWidth = width || 640;
        var popupHeight = height || 320;
        var windowLeft = window.screenLeft || window.screenX;
        var windowTop = window.screenTop || window.screenY;
        var windowWidth = window.innerWidth || document.documentElement.clientWidth;
        var windowHeight = window.innerHeight || document.documentElement.clientHeight;
        var popupLeft = windowLeft + windowWidth / 2 - popupWidth / 2 ;
        var popupTop = windowTop + windowHeight / 2 - popupHeight / 2;
        var popup = window.open(url, title, 'scrollbars=yes, width=' + popupWidth + ', height=' + popupHeight + ', top=' + popupTop + ', left=' + popupLeft);
        popup.focus();
        return true;
    };
    
    const WhatsAppHandler = (e) => {
        e.preventDefault();
        window.open(`whatsapp://send?text=Fiche du personnage Biblique :%0D${props.subject.nom}%0A${encodeURIComponent(url)}`)
    }

    const TwitterHandler = (e) => {
        e.preventDefault();
        
        var shareUrl = "https://twitter.com/intent/tweet?text=" + encodeURIComponent(document.title) +
            "&via=Neuronal_bible" +
            "&url=" + encodeURIComponent(url);
        popupCenter(shareUrl, "Partager sur Twitter");
    }

    const FaceBookHandler = (e) => {
        e.preventDefault();
        var shareUrl = "https://www.facebook.com/sharer/sharer.php?u=" + encodeURIComponent(url);
        popupCenter(shareUrl, "Partager sur facebook");
    }

    const LinkedinHandler = (e) => {
        e.preventDefault();
        var shareUrl = "https://www.linkedin.com/shareArticle?url=" + encodeURIComponent(url);
        popupCenter(shareUrl, "Partager sur Linkedin");
    }

    return (
        <div>
            <Button className="share whatsapp" onClick={WhatsAppHandler}>WhatsApp</Button>
            <Button className="share twitter" onClick={TwitterHandler}>Twitter</Button>
            <Button className="share facebook" onClick={FaceBookHandler}>FaceBook</Button>
            <Button className="share linkedin" onClick={LinkedinHandler}>Linkedin</Button>
            <ModalReportError subject={props.subject}/>
        </div>
    )
}