import React, {Component} from 'react';
import axios from 'axios';
import "./Chefdetroupe.css";
import Bible from '../Bible/Bible';

class Chefdetroupe extends Component {
    constructor(props){
        super(props);
        this.state = {subjectData : null};
    }

    fetchSubjectData = async (next_list_no_fait) =>{
        const request = `https://subject-data-service.neuronal.bible/chefdetroupe?list_no_fait=${next_list_no_fait}`;
        const fetchSubjectData = await axios.get(request);//Call subject-data-service
        this.setState({subjectData: fetchSubjectData.data});
    }

    componentDidMount = () => {
        this.fetchSubjectData(this.props.subject.list_no_fait);
    }

    componentDidUpdate(prevProps) {
        // Typical usage (don't forget to compare props):
        if (this.props.subject !== prevProps.subject) {
          this.fetchSubjectData(this.props.subject.list_no_fait);
        }
    }
      
    render(){
        if (this.state.subjectData == null || this.state.subjectData.length === 0)
            return(<span data-tag="chefdetroupe"></span>);
        else
            return(
                        <span data-tag="chefdetroupe">
                            <span>&nbsp;A&nbsp;été&nbsp;chef&nbsp;de&nbsp;troupe</span>
                            {this.state.subjectData.bible &&
                                <span>
                                    <Bible bibleref={this.state.subjectData.bible}/>
                                </span>}
                        </span>
                    );
    }
};   

export default Chefdetroupe;