import { Component  } from "react";
import './IntroView.css'

class IntroView extends Component{
    constructor(props){
        super(props)
    }

    render(){
        return(
            <div className="container intro-view">
                <h4>Genèse de <strong>neuronal.bible</strong></h4>

                <p><strong>neuronal.bible</strong> est un système expert dont l’intelligence
                artificielle permet d'interroger le texte biblique pour mettre en évidence des
                relations entre des personnages, des lieux, des évènements, des thématiques...
                On peut ainsi interroger rapidement et de façon intuitive le contenu de la
                Bible pour en extraire des informations structurées qui pourront enrichir et
                faciliter l'étude du texte. Le but n'est pas de remplacer l'étude et la
                mémorisation de la Parole de Dieu mais plutôt de faciliter les recherches pour
                mettre en lumière la cohérence et la structure du Livre des livres. Pour
                fournir ces précieuses informations, le système s'appuie sur une modélisation factuelle
                des données tirées du texte biblique.</p>

                <h4>Quel est le principe ?</h4>

                <p>Pour les rendre <b>interrogeables</b>,
                elles ont été étudiées, structurées et soigneusement ordonnées pendant
                plusieurs années.</p>

                <p>Il s’agit en effet d’un travail titanesque
                initié en 2015. Fruit de l’imagination combiné à un besoin évident de tirer la
                quintessence de la <i>Parole de Dieu</i>, <strong>neuronal.bible</strong> a fait l’objet de la
                conception d’un cerveau virtuel techniquement appelé moteur d’inférence.</p>

                <h4>Application pratique</h4>

                <p>Nous
                regardions un film consacré à l’Histoire de Salomon quand mon épouse s’est
                exclamée&nbsp;:&nbsp;</p>

                <ul>
                    <li>
                        <p>«&nbsp;Comment
                        s’appelle l’épouse officielle du roi Salomon&nbsp;» ?</p>
                    </li>

                    <li>
                        <p>Ne connaissant pas la réponse, j’interroge <strong>neuronal.bible</strong> à propos de Salomon.</p>
                    </li>

                    <li>
                        <p><strong>neuronal.bible</strong> répond que la Bible ne la nomme pas mais qu’elle est néanmoins
                        identifiée comme la fille du Pharaon.</p>
                    </li>
                
                </ul>

                <p><span>neuronal.bible prouve cette assertion
                en fournissant les références bibliques <b><i>2 Chr 8.11</i></b>, <b><i>1 Rois
                3.1</i></b> qui s’y rapportent.</span></p>

                <p>&nbsp;</p>

                <h4>A vous de jouer !</h4>

                <p>Commencez dès maintenant vos études Bibliques. Entrez le nom du personnage central de votre étude et laissez vous guider par le <i>Saint Esprit</i></p>

                <q>Recherchez l'Eternel pendant qu'il se laisse trouver! Faites appel à lui tant qu'il est près! <b>(Esaïe 55.6)</b></q>
            </div>
        )
    }
}

export default IntroView;