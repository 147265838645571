import React, {Component} from 'react';
import './SubjectEmphasis.css';

class SubjectEmphasis extends Component{
    constructor(props){
        super(props);
    }

    render(){
        return(
            <span className="active-subject-emphasis">{this.props.text.nom}</span>
        );
    }
}

export default SubjectEmphasis;