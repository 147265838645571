/*
SubjectView
*/

import React, {Component, useEffect, useState} from 'react';
import { useHistory } from 'react-router';
import Subject from '../Components/Subject/Subject';
import axios from 'axios';
import SubjectList2 from '../Components/SubjectList2/SubjectList2';
import './SubjectsView.css';
import {globals} from '../Components/Globals/Globals'
import ReactEcharts from "echarts-for-react";
import GoJs4React from '../Components/GoJs4React/GoJs4React'


import {
    // LineChart,
    //BarChart,
    // PieChart,
    // ScatterChart,
    // RadarChart,
    // MapChart,
    // TreeChart,
    // TreemapChart,
     GraphChart,
    // GaugeChart,
    // FunnelChart,
    // ParallelChart,
    // SankeyChart,
    // BoxplotChart,
    // CandlestickChart,
    // EffectScatterChart,
    // LinesChart,
    // HeatmapChart,
    // PictorialBarChart,
    // ThemeRiverChart,
    // SunburstChart,
    // CustomChart,
  } from 'echarts/charts';

const option = {
    xAxis: {
      type: "category",
      data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
    },
    yAxis: {
      type: "value",
    },
    series: [
      {
        data: [120, 200, 150, 80, 70, 110, 130],
        type: "bar",
      },
    ],
  };

const SubjectsView = () => {
    let history = useHistory();
    console.log("history =", history)

    useEffect(()=>{
        //let history = useHistory();
        //console.log("history =", history)
    })

   /*  Prototype : listOnClickSubjectHandler
    *  Purpose   : Open Dialog with selected Subject data
    *  Parameters: Selected Subject object
    *  History   : Created on 2020-12-30
    */
    const OnClickSubjectHandler = (subject) => {
        if (globals.parameters.local){
            console.log("OnClickSubjectHandler subject:", subject);
            //Push selected subject to navigator
            console.clear();
            console.log("Selected subject:", subject);
            console.log("history => ",history)    
        }
        
        //history.pushState        
        history.push(`/sujet/${subject.list_no_fait}`);
    }

    const errorHandler = (childClass, errorMessage) => {
        let errorFullMessage = `error occurs on ${childClass.constructor.name} with message:"${errorMessage}"`
        console.error(errorFullMessage)
        alert(errorFullMessage)
    }
    
    //rendering
    return (
        <div id="main-container" className="container"> 
            <h4>Navigation par personnages</h4>
            <q>Recherchez un personnage biblique pour débuter votre étude !</q>

            <SubjectList2 
                maxResults={9}
                /*placeholder={"Nom du personnage Biblique"}*/
                placeholder={""}
                mode="linguee" 
                OnClickSubject_callback= {OnClickSubjectHandler}/>

            {/** Dynamic breadcrumb */}
            {globals.parameters.local &&
            <div id="subject_history">
                <div className='nav back' onClick={()=>{history.goBack()}}>Précédent</div>
                <div className='nav forward' onClick={()=>{history.goForward()}}>Suivant</div>
                <div>
                    <ul>
                        <li>Jean</li>
                        <li>Jésus</li>
                        <li>Aristarque</li>
                    </ul>
                </div>                
            </div>}

            {/* Echart for react */}
            {globals.parameters.graph_enabled &&<div>
                <ReactEcharts option={option} />;            
            </div>}

            {/** GoJs for React */}
            {globals.parameters.graph_enabled && <GoJs4React/>}

            <br/>
            <q>Cliquez les liens interactifs ci-dessous pour naviguer dans la Bible</q>

            <Subject 
                handler={null}
                errCallback={errorHandler}/>     
        </div>
        );
}

export default SubjectsView;