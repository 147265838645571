import React, {Component} from 'react';
import axios from 'axios';
import "./Tue.css";
import SubjectButton from '../SubjectButton/SubjectButton';
import SubjectEmphasis from '../SubjectEmphasis/SubjectEmphasis';
import Bible from '../Bible/Bible';

class Tue extends Component {
    constructor(props){
        super(props);
        this.state = {subjectData : null};
    }

    fetchSubjectData = async (next_list_no_fait) =>{
        const request = `https://subject-data-service.neuronal.bible/Tue?list_no_fait=${next_list_no_fait}`;
        const fetchSubjectData = await axios.get(request);//Call subject-data-service
        this.setState({subjectDataItems: fetchSubjectData.data.items, list_no_fait:next_list_no_fait}, ()=> {console.log("Tue subjectDataItems=>", this.state.subjectDataItems)});
    }

    componentDidMount = () => {
        this.fetchSubjectData(this.props.subject.list_no_fait);
    }

    componentDidUpdate(prevProps) {
        // Typical usage (don't forget to compare props):
        if (this.props.subject !== prevProps.subject) {
          this.fetchSubjectData(this.props.subject.list_no_fait);
        }
    }
      
    render(){
        if (this.state.subjectDataItems == null)
            return(<span data-tag="tue"></span>);
        else
            return(
            <div data-tag="tue" class="verbs" key="{subjectData.list_no_fait}">{
                this.state.subjectDataItems.map(
                    subjectData => {
                        return(    
                            <div key={subjectData.list_no_fait}> 
                                {(subjectData._sujet_flag) &&
                                    <span>
                                        <SubjectEmphasis text={this.props.subject}/>
                                        <span class="light-italic">&nbsp;&agrave;&nbsp;causé&nbsp;la&nbsp;mort&nbsp;de&nbsp;</span>
                                    </span>                 
                                }

                                {(subjectData._victime_flag)&&
                                    <span>
                                        <SubjectEmphasis text={this.props.subject}/>
                                        <span class="light-italic">&nbsp;victime&nbsp;de&nbsp;</span>
                                    </span>
                                }
                                <SubjectButton list_no_fait={subjectData.victime} text={subjectData._victime} handler={this.props.handler}></SubjectButton>
                                
                                {subjectData.bible &&
                                    <span>
                                        <Bible bibleref={subjectData.bible}/>
                                    </span>
                                }
                            </div>);
                    })}
            </div>);
    }
};   

export default Tue;