/*
Neuronal Client App
*/

import React, {Component, useState} from 'react';
import {Route, NavLink, Switch, BrowserRouter, useHistory} from "react-router-dom"
import Neuronal from './Components/Neuronal/Neuronal';
import IntroView from './Views/IntroView';
import SubjectsView  from './Views/SubjectsView';
import NewsLetterView  from './Views/NewsLetterView';
import DonateView from './Views/DonateView';
import ContactView from './Views/ContactView';
import HomeView from './Views/HomeView';

import './App.css'

const App = () => {

    //Rendering
    return (
        <BrowserRouter>
            <div>
                <Neuronal/>
                <ul className="header">
                    <li><NavLink exact to="/">Neuronal.Bible</NavLink></li>
                    <li><NavLink to="/sujet">Personnages bibliques</NavLink></li>                   
                    {/*
                    <li><NavLink to="/newsletter/Denise">News letter</NavLink></li>*/}
                    <li><NavLink to="/contact">Nous contacter</NavLink></li>
                    <li><NavLink to="/about">A propos</NavLink></li>
                    <li><NavLink to="/donate">Dons</NavLink></li>
                </ul>
                <Switch>
                    <Route  exact path = "/" component={HomeView} />
                    <Route  path = "/wordtree/:no_list_fait" component={HomeView} />
                    <Route  exact path = "/sujet" component={SubjectsView} /> {/* Route with no parameter */}
                    <Route  path = "/sujet/:no_list_fait" component={SubjectsView} />{/* Route with parameter */}
                    <Route  path = "/donate" component={DonateView} />
                    <Route  path = "/newsletter/:no_list_fait" component={NewsLetterView} />
                    <Route  path = "/contact" component={ContactView} />
                    <Route  path = "/about" component={IntroView} />
                </Switch>
                <p>&copy;2005-2022 Patrick Mabialah</p>
            </div>
        </BrowserRouter>  
    );
    
}

export default App;