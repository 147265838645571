import React, {Component} from 'react';
import axios from 'axios';
import "./SubjectAliases.css";
import Bible from '../Bible/Bible';
import SubjectEmphasis from '../SubjectEmphasis/SubjectEmphasis';
import SubjectButton from '../SubjectButton/SubjectButton';

class SubjectAliases extends Component {
    constructor(props){
        super(props);
        this.state = {subjectAliasesItems : null};
    }

    fetchSubjectData = async (next_list_no_fait) =>{
        const localURL = `http://localhost:4005/common-and-aliases?list_no_fait=${next_list_no_fait}`;
        const remoteURL = `https://subject-aliases-service.neuronal.bible/common-and-aliases?list_no_fait=${next_list_no_fait}`;
        const request = (["localhost", "127.0.0.1"].includes(window.location.hostname))?localURL:remoteURL

        const fetchSubjectAliases = await axios.get(request);//Call subject-data-service
        this.setState({subjectAliasesItems: fetchSubjectAliases.data});

        console.log("fetchSubjectData.data from subject-aliases-service :", fetchSubjectAliases.data);
    }

    componentDidMount = () => {
        this.fetchSubjectData(this.props.subject.list_no_fait);
    }

    componentDidUpdate(prevProps) {
        // Typical usage (don't forget to compare props):
        console.log("SubjectAliases : componentDidUpdate")
        if (this.props.subject !== prevProps.subject) {
          this.fetchSubjectData(this.props.subject.list_no_fait);
        }
    }

    render(){
        if (this.state.subjectAliasesItems){
            let alias_list = 
                this.state.subjectAliasesItems.slice(1).map(
                        (subjectData,ii) => {
                            console.log("subjectData ====>", subjectData.name)
                            return (
                                    <span key="{subjectData.auto_id}">
                                        {(ii > 0) && " / "}
                                        {subjectData.name}
                                    </span>
                            );
                        }
                    );
            return(
                <div>
                {/* Moved from <Introduction/> component */}
                <span class="personnage">{this.state.subjectAliasesItems[0].name}</span>&nbsp;<span class="gender">{this.props.subject.sexe}</span> 
                {this.state.subjectAliasesItems.length > 1 &&
                <span data-tag="subject-aliases">
                    {(this.props.subject.sexe === 'H') &&
                        <span class="light-italic">&nbsp;surnommé&nbsp;</span>}
                    {(this.props.subject.sexe === 'F') &&
                        <span class="light-italic">&nbsp;surnommée&nbsp;</span>}

                    {alias_list}
                </span>}
                </div>
                );
        }
        else  return(<></>)        
    }
};   

export default SubjectAliases;