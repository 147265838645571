/*
SubjectList2 component
*/

import React, { Component } from 'react';
import axios from 'axios';
import {DropdownMenu, DropdownItem, ButtonDropdown, DropdownToggle} from "reactstrap";
import styled from 'styled-components';
import "./SubjectList2.css"
import { globals } from '../Globals/Globals';

const LightItalic = styled.span`
    color:gray;
    font-style: italic;
`

const UnknownAgreement = styled.span`
    margin-left: 15px;
`

const LowInvolve = styled.span`
    opacity: 0.1;
    &:before {
        font-family: "Font Awesome 5 Free"; font-weight: 900; content: "plug";
      }
`;

const MediumInvolve = styled.span`
    opacity: 0.5;
    &:before {
        font-family: "Font Awesome 5 Free"; font-weight: 900; content: "plug";
      }
`;

const HighInvolve = styled.span`
    opacity: 1;
    &:before {
        font-family: "Font Awesome 5 Free"; font-weight: 900; content: "plug";
      }
`;

const ThumbsUp = styled.span`
    &:before {
        font-family: "Font Awesome 5 Free"; font-weight: 900; content: "thumbs-up";
      }
`;

const ThumbsDown = styled.span`
    &:before {
        font-family: "Font Awesome 5 Free"; font-weight: 900; content: "thumbs-down";
      }
`;

const SujetContainer = styled.div`
    padding: 2px;
    cursor: pointer;
    &:hover {
        background-color: lightgreen;
    }`;

const Scrollable = styled.div`
    width: 300px;
    height: 150px;
    padding: 2px;
    overflow: scroll
    `;

class SubjectList2 extends Component{
    constructor(props){
        super(props);
        this.state = {subjects:[], selected_subject:null, filter:"", is_open:false};
    }

    componentDidMount = () => {
        this.fetchSubjects();
    }

    fetchSubjects = async () => {
        const fetchresult = await axios.get(globals.list_service_subjects_endpoint.url);
        if (globals.parameters.debugging) 
            console.log(fetchresult.data.items);
        this.setState({subjects : fetchresult.data.items});
    };

    onFilterChange = (e) => {
        this.setState({filter:e.target.value, is_open:true})
    }

    //-----------------------------------------------------------------------------------------------------------------------------------------------------------------
    subjectItemRendered = (subject) => {
        if (!subject)
            return(<span>Choisir un sujet ...</span>)
        else
            return(
                <span>
                    {subject.low_involve && 
                        <LowInvolve title="Personnage non connecté au réseau neuronal">&nbsp;</LowInvolve>}

                    {subject.medium_involve &&
                        <MediumInvolve title="Personnage en cours de connexion réseau neuronal">&nbsp;</MediumInvolve>}

                    {subject.hi_involve &&
                        <HighInvolve title="Personnage connecté au réseau neuronal">&nbsp;</HighInvolve>}

                    {(subject.is_king_agreed == 1) && <ThumbsUp title="Agréé de Dieu"/>}
                    {(subject.is_king_agreed == 0) && <ThumbsDown title="Rejeté par Dieu"/>}
                    {(subject.is_king_agreed == null) && <UnknownAgreement/>}
                        
                    <span>&nbsp;{subject.nom}</span>  
                    {subject._pere &&
                        <span>
                            {subject.fils_de &&
                                <LightItalic> fils de </LightItalic>
                            }

                            {subject.fille_de &&
                                <LightItalic> fille de </LightItalic>
                            }

                            {subject._pere}
                        </span>
                    }
                    {/*
                    {subject.bible &&
                        <span>
                            {subject.bible}
                        </span>
                        
                    }*/}
                </span>
            );
    }

    //-----------------------------------------------------------------------------------------------------------------------------------------------------------------
    listRenderer = () => {
        const renderedSubjects = this.state.subjects.filter((aSubject)=>aSubject.nom.toLowerCase().indexOf(this.state.filter.toLowerCase(),0) == 0).map(
            subject => {
                return (
                        <SujetContainer key={subject.auto_id} onClick = {()=>{this.props.OnClickSubject_callback(subject)}}>
                            {this.subjectItemRendered(subject)}
                        </SujetContainer>
                )
            }
        );

        return renderedSubjects;
    }

    //-----------------------------------------------------------------------------------------------------------------------------------------------------------------
    comboRenderer = () => {
        const renderedSubjects = this.state.subjects.filter((aSubject)=>aSubject.nom.toLowerCase().indexOf(this.state.filter.toLowerCase(),0) == 0).map(
            subject => {
                return (
                    <DropdownItem key={subject.auto_id} onClick = {() => {/*console.log("DropdownItem Click => ",subject.list_no_fait);this.setState({selected_id:subject.list_no_fait, selected_subject:subject});*/this.props.OnClickSubject_callback(subject);}}>
                        <SujetContainer >
                            {this.subjectItemRendered(subject)}
                        </SujetContainer>
                    </DropdownItem>
                )
            }
        )

        return(
            <div>
                <ButtonDropdown isOpen={this.state.open} toggle={() => {this.setState({open:!this.state.open});}}>
                    <DropdownToggle caret>
                        {this.subjectItemRendered(this.state.selected_subject)}
                    </DropdownToggle>
                        <DropdownMenu>
                            <input autoFocus id="dropdown_input_filter" onChange={(e) => {this.onFilterChange(e);}} type="search" value={this.state.filter} placeholder="Filtre de recherche ..."></input>
                            <Scrollable>
                                {renderedSubjects}
                            </Scrollable>
                        </DropdownMenu>
                </ButtonDropdown>
            </div>
        )
    }

    //-----------------------------------------------------------------------------------------------------------------------------------------------------------------
    selectRenderer = () => {
        const renderedSubjectsHtmlOptionsItems = this.state.subjects.filter((aSubject)=>aSubject.nom.toLowerCase().indexOf(this.state.filter.toLowerCase(),0) == 0).map(
            subject => {
                return (
                    <option key={subject.auto_id} value={subject.auto_id} onClick = {() => {/*console.log("DropdownItem Click => ",subject.list_no_fait);this.setState({selected_id:subject.list_no_fait, selected_subject:subject});*/this.props.OnClickSubject_callback(subject);}}>
                            {subject.nom}{/*{this.subjectItemRendered(subject)}*/}
                    </option>
                )
            }
        )

        return(
            <select>
                {renderedSubjectsHtmlOptionsItems}
            </select>
        )
    }

    //---
    lingueeResultsRenderer = () => {
        const renderedlingueeResults = this.state.subjects.filter((aSubject)=>aSubject.nom.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").indexOf(this.state.filter.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""),0) == 0).slice(0,this.props.maxResults).map(
            subject => {
                return (
                    <div 
                        class="autocompletion_item" 
                        key={subject.auto_id}
                        onClick = {()=>{this.close(); this.props.OnClickSubject_callback(subject)}}>
                        <div class="main_row">
                            <div class="main_item" href="/francais-anglais/traduction/accueil+des+clients.html">{subject.nom}</div>
                            <div class="subject-gender">
                                {(subject.sexe == "H") && <span title="Personnage de sexe Masculin">H</span>}
                                {(subject.sexe == "F") && <span title="Personnage de sexe féminin">F</span>}
                            </div>
                        </div>
                        <div class="translation_row line singleline">
                            <div>
                                <div class="translation_item">
                                    <span>
                                        {subject.low_involve && 
                                            <span className="fa fa-plug low-involve" title="Personnage non connecté au réseau neuronal">&nbsp;</span>}

                                        {subject.medium_involve &&
                                            <span className="fa fa-plug medium-involve" title="Personnage en cours de connexion réseau neuronal">&nbsp;</span>}

                                        {subject.hi_involve &&
                                            <span className="fa fa-plug high-involve" title="Personnage connecté au réseau neuronal">&nbsp;</span>}

                                        {(subject.is_king_agreed == 1) && <span className="fa fa-thumbs-up" title="Agréé de Dieu"/>}
                                        {(subject.is_king_agreed == 0) && <span className="fa fa-thumbs-down" title="Rejeté par Dieu"/>}
                                        {(subject.is_king_agreed == null) && <span className="UnknownAgreement"/>}
                        
                                        <span>&nbsp;{subject.nom}</span>  
                                        {subject._pere &&
                                            <span>
                                                {subject.fils_de &&
                                                    <LightItalic> fils de </LightItalic>
                                                }

                                                {subject.fille_de &&
                                                    <LightItalic> fille de </LightItalic>
                                                }

                                                {subject._pere}
                                            </span>
                                        }
                                        {/** Display mother if father is not available */}
                                        {(!subject._pere && subject._mere) &&
                                            <span>
                                                {subject.fils_de &&
                                                    <LightItalic> fils de </LightItalic>
                                                }

                                                {subject.fille_de &&
                                                    <LightItalic> fille de </LightItalic>
                                                }

                                                {subject._mere}
                                            </span>
                                        }
                                    </span>
                                    {subject.bible &&
                                    <div class="father-thumb-bible-ref">
                                        <span title="première apparition dans la Bible">{subject.bible}</span>
                                    </div>}
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        );

        return(
            this.state.is_open ?
            <div id="autocompletion" class="live">
                <div class="autocompletion">
                {renderedlingueeResults}
                </div>

                {/*
                <div id="press-enter" class="_acSearchHint">
                    <span>Appuyez sur Entrée pour chercher «&nbsp;<span class="querytext">accuei</span>&nbsp;»</span>
                </div>*/}
            </div>
            : <div></div>
        )
    }

    close = (e) =>{
        /*
        e.preventDefault();*/
        this.setState({is_open:false})
    }

    open = () => {
        console.log("Focus => Open")
        this.setState({is_open:true})
    }

    //---
    ligueeRenderer = () =>{
        return(
            <div id="header" class="wide_in_main l_header">
                {this.state.is_open &&
                <div id="contentoverlay" onClick={this.close}/>}
                    <div class="lQueryHeader">
                        <div class="lQueryHeader__input_container">
                            <input 
                            type="text" 
                            name="query" 
                            id="queryinput" 
                            maxlength="1000" 
                            autocomplete="off" 
                            autocorrect="off" 
                            autocapitalize="off" 
                            spellcheck="false"
                            placeholder={this.props.placeholder}
                            onChange={(e) => {this.onFilterChange(e);}}
                            onFocus={(e) => {this.setState({is_open:true})}} 
                            value={this.state.filter} tabindex="1" autofocus=""/>
                            <button type="submit" alt="Rechercher un personnage biblique"></button>
                            <div class="lQueryHeader__ac_container"></div>
                        </div>
                    </div>
                    {this.lingueeResultsRenderer()}
            </div>
        )
    }

    render(){
        if ((this.props.mode && this.props.mode === "linguee")){
            return (<div>{this.ligueeRenderer()}</div>);
        }
        if ((this.props.mode && this.props.mode === "select")){
            return (<div>{this.selectRenderer()}</div>);
        }
        else{
            if ((this.props.mode && this.props.mode === "combo")){
                return (<div>{this.comboRenderer()}</div>);
            }
            else{
                return (<div>{this.listRenderer()}</div>);
            }
        }
    }
}

export default SubjectList2;