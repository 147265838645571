import React, {Component} from 'react';
import axios from 'axios';
import "./Epoux.css";
import Bible from '../Bible/Bible';
import SubjectButton from '../SubjectButton/SubjectButton';
import SubjectEmphasis from '../SubjectEmphasis/SubjectEmphasis';

class Epoux extends Component {
    constructor(props){
        super(props);
        this.state = {subjectDataItems : []};
    }

    fetchSubjectData = async (next_list_no_fait) =>{
        const localURL = `http://localhost:4007/epoux`;
        const remoteURL = `https://neuronal-epoux-service.neuronal.bible/epoux`;
        const request = (["localhost", "127.0.0.1"].includes(window.location.hostname))?localURL:remoteURL

        //call requete-service
        axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
        try{
            const fetchSubjectData = await axios.post( 
                request, 
                {list_no_fait:next_list_no_fait});//Call subject-data-service
            console.log("fetchSubjectData:",fetchSubjectData)
            if (fetchSubjectData.data.state === true)
                this.setState({subjectDataItems: fetchSubjectData.data.items, list_no_fait:next_list_no_fait});
            else
                this.props.errCallback(this,"Erreur de connexion")
        }
        catch(e){
            alert("Vérifiez votre connexion Internet");
            console.clear()
            console.error("erreur:",e)
        }
    }

    componentDidMount = () => {
        this.fetchSubjectData(this.props.subject.list_no_fait);
    }

    componentDidUpdate(prevProps) {
        // Typical usage (don't forget to compare props):
        if (this.props.subject !== prevProps.subject) {
          this.fetchSubjectData(this.props.subject.list_no_fait);
        }
    }
      
    render(){
        if (this.state.subjectDataItems === null || this.state.subjectDataItems.length === 0)
            return(<span data-tag="epoux"></span>);
        else
            return(
                <div data-tag="epoux" class="verbs" key="{subjectData.list_no_fait}">{
                    this.state.subjectDataItems.map(
                        subjectData => {
                            return(
                                <div key={subjectData.list_no_fait}>
                                    {(subjectData.sujet == this.state.list_no_fait) &&
                                        <span>
                                            <SubjectEmphasis text={this.props.subject}/> 
                                            {(this.props.subject.sexe === 'H') &&
                                                <span className ="light-italic">&nbsp;époux de&nbsp;</span>
                                            }
                                            {(this.props.subject.sexe === 'F') &&
                                                <span className ="light-italic">&nbsp;épouse de&nbsp;</span>
                                            }
                                            <SubjectButton list_no_fait={subjectData.epouse} text={subjectData._epouse} handler={this.props.handler}></SubjectButton>
                                        </span>
                                    }

                                    {(subjectData.epouse == this.state.list_no_fait) &&
                                        <span>
                                            <SubjectEmphasis text={this.props.subject}/>
                                            {(this.props.subject.sexe === 'H') &&
                                                <span className ="light-italic">&nbsp;époux de&nbsp;</span>
                                            }
                                            {(this.props.subject.sexe === 'F') &&
                                                <span className ="light-italic">&nbsp;épouse de&nbsp;</span>
                                            }
                                            <SubjectButton list_no_fait={subjectData.sujet} text={subjectData._sujet} handler={this.props.handler}></SubjectButton>
                                        </span>
                                    }

                                    {subjectData.bible &&
                                        <span>
                                            <Bible bibleref={subjectData.bible}/>
                                        </span>
                                    }
                                </div>);
                            })}
                </div>);
    }
};   

export default Epoux;