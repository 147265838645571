import React from 'react';
import { useHistory } from 'react-router';

const SubjectButton = (props) => {
    let history = useHistory();

    const SubjectNavButton = () => {

    }

    //rendering
    return(
        <button 
            class="item-button" 
            onClick={()=>{ 
                            history.push(`/sujet/${props.list_no_fait}`,{list_no_fait:props.list_no_fait})}}>{props.text}</button>
    )
}

export default SubjectButton;