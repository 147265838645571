import React, {Component} from 'react';
import axios from 'axios';
import "./Roi.css";
import Bible from '../Bible/Bible';
import SubjectButton from '../SubjectButton/SubjectButton';
import SubjectEmphasis from '../SubjectEmphasis/SubjectEmphasis';

class Roi extends Component {
    constructor(props){
        super(props);
        this.state = {subjectDataItems : null};
    }

    fetchSubjectData = async (next_list_no_fait) =>{
        const localURL = `http://localhost:5002/roi?list_no_fait=${next_list_no_fait}`;
        const remoteURL = `https://roi-service.neuronal.bible/roi?list_no_fait=${next_list_no_fait}`;
        const roiServiceURL = (["localhost", "127.0.0.1"].includes(window.location.hostname))?localURL:remoteURL
        
        console.log("roiServiceURL =>", roiServiceURL)
        await axios.get(roiServiceURL).then(
            result => {
                console.log("Roi service call succedeed result:", result);
                this.setState({subjectDataItems: result.data});
            }
        ).catch(
            error => {
                console.error("Roi service call failed with error:", error);
            }
        )
    }

    componentDidMount = () => {
        this.fetchSubjectData(this.props.subject.list_no_fait);
    }

    componentDidUpdate(prevProps) {
        // Typical usage (don't forget to compare props):
        if (this.props.subject !== prevProps.subject) {
          this.fetchSubjectData(this.props.subject.list_no_fait);
        }
    }

    render(){
        if (this.state.subjectDataItems === null || this.state.subjectDataItems.length === 0)
            return(<span data-tag="roi"></span>);
        else
            return(
                <span data-tag="roi">
                    {
                    this.state.subjectDataItems.map(
                        subjectData => {
                            return (
                                    <div>
                                        <SubjectEmphasis text={this.props.subject}/>
                                        {(this.props.subject.sexe === 'H') &&
                                            <span class="light-italic">&nbsp;proclam&eacute;&nbsp;Roi&nbsp;de&nbsp;</span>}
                                        {(this.props.subject.sexe === 'F') &&
                                            <span class="light-italic">&nbsp;proclam&eacute;e&nbsp;Reine&nbsp;de&nbsp;</span>}

                                        <span>{subjectData.royaume}</span>
                                        {(subjectData.age_accession) && <span>&nbsp;à&nbsp;l'age de {subjectData.age_accession}&nbsp;ans</span>}

                                        {subjectData.chronologie &&
                                            <span>
                                                <span>&nbsp;la&nbsp;{subjectData.chronologie_annee}<span class="upper">ème</span>&nbsp;année&nbsp;de&nbsp;</span>
                                                <SubjectButton list_no_fait={subjectData.chronologie_roi} text={subjectData._chronologie_roi}></SubjectButton>
                                            </span>
                                        }

                                        {(subjectData.duree_regne) && <span>,&nbsp;reigna {subjectData.duree_regne}&nbsp;{subjectData.unite_regne}</span>}
                                        {(subjectData.agree_de_dieu == 1) && <span>. Ce règne fût agréé de Dieu</span>}
                                        {(subjectData.agree_de_dieu == 0) && <span>. Ce règne fût rejeté par Dieu</span>}

                                        {subjectData.bible &&
                                            <span>
                                                <Bible bibleref={subjectData.bible}/>
                                            </span>}
                                    </div>
                            );
                        }
                    )}
                </span>);
    }
};   

export default Roi;