import React, {Component} from 'react';
import axios from 'axios';
import './Bible.css';

class BibleLink extends Component{
    constructor(props){
        super(props);
        this.state={loaded:false,open:false, bible_texts:[]}
        this.BibleServiceUrl = "https://neuronal.bible/json/gateway/bible_service.php?bible_ref="
    }

    componentDidMount = () => {
        this.setState({loaded:false,open:false, bible_texts:[]}, console.log("Bible state:",this.state))
    }

    componentDidUpdate(prevProps){
        if (this.props.bibleref !== prevProps.bibleref){
            console.log("ComponentDidUpdate:", prevProps.bible_ref)
            this.setState({loaded:false,open:false, bible_texts:[]})
        }
    }

    componentWillUnmount = () =>{
        this.setState({open:false})
        console.log("Bible component will unmount... ")
    }

    loadBibleContent = async () => {
        if (this.state.loaded == true) return true

        let bibleServiceResult = await axios.get(`${this.BibleServiceUrl}${this.props.bibleref}`)
        console.log("bibleServiceResult:", bibleServiceResult, "err_code:",bibleServiceResult.data.err_code)
        if ((bibleServiceResult.data.err_code == "-1") && (bibleServiceResult.data.bible_ref.length > 0)){
            this.setState(
                {loaded:true,bible_texts:bibleServiceResult.data.bible_ref}, ()=>{console.log("Bible state:",this.state)}
            )
            return true
        }
        return false
    }

    onCloseHandler = () => {
        this.setState({open:false})
    }

    clickHandler = () => {
        if (this.state.open){
            this.setState({open:false}, console.log("Bible state:",this.state))
        }
        else {
            if (this.loadBibleContent())
                this.setState({open:true}, console.log("Bible state:",this.state))
        }
    }

    render(){
        if (this.props.bibleref)
            return(
                <span className="bible-component">
                    <span 
                        className="bible-text" 
                        onClick={()=>{this.clickHandler()}}>{this.props.bibleref}</span>
                    {((this.state.open) && (this.state.bible_texts.length > 0)) &&
                        <span className="bible-text-content" onClick={()=>{this.onCloseHandler()}}>
                            <hr className="inset"/>
                            <span className="bible-title-ref" >{this.props.bibleref}</span><br/>
                            {this.state.bible_texts.map(verse => {
                                    if (
                                        (verse.class.length > 0) 
                                        && (verse.class.indexOf("Tt11") < 0) 
                                        && (verse.class.indexOf("Tt2") < 0) 
                                        && (verse.class.indexOf("Tx5") < 0) 
                                        && (verse.class.indexOf("Tt0sup") < 0) 
                                        && (verse.class.indexOf("Tx01sup") < 0) 
                                        && (verse.class.indexOf("Tx0") < 0) 
                                        && (verse.class.indexOf("date") < 0))
                                        return(<span className={verse.class}>{verse.decode}</span>)
                                    })}
                            <hr className="inset"/>
                        </span>
                    }
                </span>
                );
        else
            return(<span></span>);
    }
}

class Bible extends Component{
    constructor(props){
        super(props);
        this.state={loaded:false,open:false, bible_texts:[]}
        this.BibleServiceUrl = "https://neuronal.bible/json/gateway/bible_service.php?bible_ref="
    }

    render(){
        if (this.props.bibleref)
            return(
                <span>
                    {this.props.bibleref.split(";").map(sub_ref => {
                        return(<BibleLink bibleref={sub_ref}/>)
                    })}
                </span>
                );
        else
            return(<span></span>);
    }
}

export default Bible;