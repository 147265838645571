/*
NewsLetterView
*/

import { Component  } from "react";
import {Route, NavLink, HashRouter, useHistory, useParams} from "react-router-dom"

const NewsLetterView  = () => {

    let { no_list_fait } = useParams();
    console.log("-----------------------", no_list_fait)
    console.log("no_lis_fait from useParams", no_list_fait)

    function GetParams () {
       let { no_list_fait } = useParams();
        console.clear()
        console.log(`window.location =`, window.location)
        console.log(`no_list_fait parameter from useParams => ${no_list_fait}`)
        return <div>GetParams from useParams() : {no_list_fait}</div>;
    }

    return(
    <div>
        <h1>Souscription à la newsletter</h1>
        <GetParams />
    </div>)
}

export default NewsLetterView;