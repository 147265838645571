import React, {Component} from 'react';
import axios from 'axios';
import "./Contemporain.css";
import Bible from '../Bible/Bible';
import SubjectButton from '../SubjectButton/SubjectButton';
import SubjectEmphasis from '../SubjectEmphasis/SubjectEmphasis';

class Contemporain extends Component {
    constructor(props){
        super(props);
        this.state = {subjectDataItems : null};
    }

    fetchSubjectData = async (next_list_no_fait) =>{
        const request = `https://subject-data-service.neuronal.bible/contemporain?list_no_fait=${next_list_no_fait}`;
        //Call subject-data-service
        await axios.get(request).then(
            result => {
                this.setState({subjectDataItems: result.data});
                console.log("Contemporain succedeed with:", result);
            }
        ).catch(
            error => {
                this.setState({subjectDataItems:[]});
                console.error("Contemporain failed with error:", error);
            }
        )
        
    }

    componentDidMount = () => {
        this.fetchSubjectData(this.props.subject.list_no_fait);
    }

    componentDidUpdate(prevProps) {
        // Typical usage (don't forget to compare props):
        if (this.props.subject !== prevProps.subject) {
          this.fetchSubjectData(this.props.subject.list_no_fait);
        }
    }

    render(){
        if (this.state.subjectDataItems === null || this.state.subjectDataItems.length === 0)
        //if (true)
            return(<span data-tag="contemporain"></span>);
        else
            return(
                <span data-tag="contemporain">
                    {
                    this.state.subjectDataItems.map(
                        subjectData => {
                            return (
                                    <div key="{subjectData.list_no_fait}">
                                        <SubjectEmphasis text={this.props.subject}/>

                                        {subjectData.sujet &&
                                            <span>
                                                &nbsp;a&nbsp;cotoyé&nbsp;<SubjectButton list_no_fait={subjectData.sujet} text={subjectData._sujet}></SubjectButton>  
                                            </span>}

                                        {subjectData.chronologie &&
                                        <span>
                                            <span>la&nbsp;{subjectData.chronologie_annee}<span class="upper">ème</span>&nbsp;année&nbsp;de&nbsp;</span>
                                            <SubjectButton list_no_fait={subjectData.chronologie_roi} text={subjectData._chronologie_roi}></SubjectButton>
                                        </span>}
                                        {subjectData.bible &&
                                        <span>
                                            <Bible bibleref={subjectData.bible}/>
                                        </span>
                                        }
                                    </div>
                            );
                        }
                    )}
                </span>);
    }
};   

export default Contemporain;