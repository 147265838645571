import React, {Component} from 'react';
import axios from 'axios';
import "./Fraterie.css";
import Bible from '../Bible/Bible';
import SubjectEmphasis from '../SubjectEmphasis/SubjectEmphasis';
import SubjectButton from '../SubjectButton/SubjectButton';

class Fraterie extends Component {
    constructor(props){
        super(props);
        this.state = {subjectDataItems : null};
    }

    fetchSubjectData = async (next_list_no_fait) =>{
        const localURL = `http://localhost:4003/fraterie?list_no_fait=${next_list_no_fait}`;
        const remoteURL = `https://fraterie-service.neuronal.bible/fraterie?list_no_fait=${next_list_no_fait}`;
        const request = (["localhost", "127.0.0.1"].includes(window.location.hostname))?localURL:remoteURL

        const fetchSubjectData = await axios.get(request);//Call subject-data-service
        this.setState({subjectDataItems: fetchSubjectData.data});

        console.log("fetchSubjectData.data from fraterie-service :", fetchSubjectData.data);
    }
    
    componentDidMount = () => {
        this.fetchSubjectData(this.props.subject.list_no_fait);
    }

    componentDidUpdate(prevProps) {
        // Typical usage (don't forget to compare props):
        if (this.props.subject !== prevProps.subject) {
            this.fetchSubjectData(this.props.subject.list_no_fait);
        }
    }

    render(){
        if (this.state.subjectDataItems === null || this.state.subjectDataItems.length === 0)
            return(<span data-tag="fraterie"></span>);
        else
            return(
                <span data-tag="fraterie">
                    {
                    this.state.subjectDataItems.map(
                        subjectData => {
                            return (
                                    <div key="{subjectData.auto_id}">
                                        <SubjectEmphasis text={this.props.subject}/>
                                        {(this.props.subject.sexe === 'H') &&
                                            <span class="light-italic">&nbsp;frère de&nbsp;</span>}
                                        {(this.props.subject.sexe === 'F') &&
                                            <span class="light-italic">&nbsp;soeur de&nbsp;</span>}

                                        <SubjectButton list_no_fait={subjectData.frere} text={subjectData._frere} handler={this.props.handler}/>

                                        {subjectData.bible &&
                                            <span>
                                                <Bible bibleref={subjectData.bible}/>
                                            </span>
                                        }
                                    </div>
                            );
                        }
                    )}
                </span>);
    }
};   

export default Fraterie;