import { Component  } from "react";
import './DonateView.css'

class DonateView extends Component{
    constructor(props){
        super(props)
    }

    render(){
        return(
            <div className="container donate-view">
                <h4>Soutenez-nous !</h4>

                <p>Il reste beaucoup à faire. Nous avons
                besoin de vous pour alimenter la base de connaissance. Je consacre beaucoup de
                temps à ce projet mais cet encore bien insuffisant pour obtenir un projet
                aboutit et suffisamment exploitable. Si Dieu le permet j’aimerai me consacrer
                pleinement à ce projet. Vos dons seraient les bienvenus…</p>

                <p>&nbsp;</p>

                <h4>Faire un don</h4>
                <q>Merci à Mme Rampersan C. pour le premier don.</q>
                <p>
                Vous aussi, participez à cet ambitieux projet en procédant à un virement sur notre compte bancaire :</p>
                <div className="rib">
                <p><b>Banque:</b> Socété Générale - THONON - PLACE DES ARTS</p>
                <p><b>SWIFT/BIC:</b> SOGEFRPP</p>
                <p><b>IBAN:</b> FR76 3000 3001 0400 0223 0676 931</p>
                </div>
                <br/>
                <h4>Merci pour votre soutien et vos prières !</h4>
                <p>Priez pour que  ce  projet aboutisse, évolue et permettent à beaucoup de découvrir l'Evangile.</p>
            </div>
        )
    }
}

export default DonateView;